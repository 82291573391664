<template>
  <div id="professionnalisation" v-bind:class="{popupEnCours : inscriptionFormationEnCours }">

  <div id="fondPopup" v-if="inscriptionFormationEnCours" @click="inscriptionFormationEnCours = null"></div>
  <div id="magicUX" v-if="infosSessionEnCours" @click="infosSessionEnCours = null"></div>


    <!-- <div class="container-professionnalisation containerAffichage"> -->
    <div class="container-professionnalisation" ref="container-professionnalisation">

        <h4>{{totalFormations}} formation<span v-if="totalFormations > 1">s</span></h4>

        <div class="containerTypes" v-bind:class="totalBlocsFormationClass">
<!--
            <a href="javascript:;" @click="scrollToId(leType.code_type)" v-if="ready && tabTypesFormations.length && leType.compteur" v-bind:class="{typeInactif : !leType.compteur}" v-for="(leType, numType) in tabTypesFormations" >
-->
            <a href="javascript:;" @click="scrollToId(leType.code_type, $event)" v-if="ready && tabTypesFormationsFiltre.length"  v-for="(leType, numType) in tabTypesFormationsFiltre" >


            <div class="itemType" v-bind:class="{mult4 : numType % 4 == 3, mult3 : numType % 3 == 2, pair : numType % 2 == 0, impair : numType % 2 == 1}">
                <span class="image" ><img class="" :src="leType.imageVignette" :alt="leType.libelle" /></span>
                <span class="contenu" >{{leType.libelle}}</span>
                <span class="compteur" >{{leType.compteur}} formation<span v-if="leType.compteur != 1">s</span><i class="fa fa-angle-down"></i></span>
            </div>
            </a>

            <div v-if="!ready">
            </div>
        </div>

        <div class="containerBlocs" id="containerBlocs">
            <div v-if="ready && tabBlocsFormations.length" class="itemBloc" v-for="(leBloc, numBloc) in tabBlocsFormations" >
                <div :id="leBloc.code_type" class="containerBloc" v-if="leBloc">
                    <h3 class="titreBloc" >{{leBloc.libelle_detaille_type}}</h3>
                    <p>({{leBloc.nb_formations}} formation<span v-if="leBloc.nb_formations != 1">s</span>)</p>








<div id="popupFormation" ref="popup_formation" class="containerFormation"
     v-if="laFormation == inscriptionFormationEnCours && laFormation.frontFormationInscriptionPossible"
     v-for="(laFormation, numFormation) in leBloc.formations" >


    <i @click="inscriptionFormationEnCours = null" class="fermeture fa  fa-times" aria-hidden="true"></i>

    <div class="consigneBloc" v-if="laFormation == inscriptionFormationEnCours">Sélectionnez une session</div>
    <h4 class="titreFormation" ><span class="refFormation">{{laFormation.reference}} . </span>{{laFormation.intitule}}</h4>
    <h5 class="titreSessions" >Sessions</h5>

    <div ref="container_sessions" class="containerSessions" v-if="laFormation.Ppfsessions.length">

        <div @click="inscriptionSessionEnCours = laSession" class="itemSession" v-if="laSession.inscription_possible" v-bind:class="{sessionEnCours : inscriptionSessionEnCours == laSession}" v-for="(laSession, numSession) in laFormation.Ppfsessions" >
<!--
            <div class="clickItemSession" @click="inscriptionSessionEnCours = laSession"></div>
-->
            <span class="dates" v-if="laSession.frontDates">
                <i class="fa fa-calendar-o" aria-hidden="true"></i>
                <span v-html="laSession.frontDates"></span>
            </span>
            <span class="lieu">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>{{laSession.frontLieu}}</span>
            </span>

            <span class="etat"  v-bind:class="laSession.frontEtatClasse">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>{{laSession.frontEtat}}
                    <span class="etat1" v-if="laSession.frontEtatPlus && laSession.frontEtatPlus.length"><br/>{{laSession.frontEtatPlus}}</span>
                    <span class="etat2" v-if="laSession.frontEtatPlusPlus && laSession.frontEtatPlusPlus.length"><br/>{{laSession.frontEtatPlusPlus}}</span>
                </span>
            </span>
            <span class="infoInscription"  v-if="laSession.frontInscription">
                {{laSession.frontInscription}}
            </span>
        </div>
    </div>

    <div  class="containerInscriptionProgramme" ref="container_inscription_programme">
        <div v-if="laFormation.frontFormationInscriptionPossible" class="containerInscription" >
            <div  class="inscriptionSession">
                <a href="javascript:;" @click="inscriptionFormationEnCours = null, inscriptionSessionEnCours = null" class="bouton nonPrioritaire">
                    Annuler
                </a>
                <a v-if="!inscriptionSessionEnCours" accesskey="" href="javascript:;" class="bouton impossible">
                    Inscription
                </a>
                <a v-bind:target="targetSelonFrame" v-if="inscriptionSessionEnCours"
                   v-bind:href="inscriptionSessionEnCours.inscription_url"
                   class="bouton">
                    Inscription
                </a>
            </div>
        </div>
        <div  class="clear" ></div>
    </div>
</div>






                    <div class="containerFormation" v-bind:id="'ancre_'+laFormation.reference"  v-bind:ref="'ancre_'+laFormation.reference" v-bind:class="{autreEnCours : inscriptionFormationEnCours && laFormation != inscriptionFormationEnCours, exEnCours : laFormation == inscriptionFormationEnCours, possible : laFormation.frontFormationInscriptionPossible}" v-for="(laFormation, numFormation) in leBloc.formations" >
                        <div class="consigneBloc" v-if="laFormation == inscriptionFormationEnCours">Sélectionnez une session</div>
                        <h4 class="titreFormation" ><span class="refFormation">{{laFormation.reference}} . </span>{{laFormation.intitule}}</h4>
                        <div  class="description" v-if="laFormation.description">
                            <span v-if="laFormation.frontDescriptionIsPrerequis">Prérequis : </span>
                            <span v-if="!laFormation.frontDescriptionIsPrerequis">Information : </span>
                            {{laFormation.description}}
                        </div>
                        <div  class="sessionVide" v-if="!laFormation.Ppfsessions.length">
                            Aucune session prévue pour le moment.
                        </div>
                        <div  class="containerSessions" v-if="laFormation.Ppfsessions.length">
<div class="itemSession" v-bind:class="laSession.frontSessionClasse" v-for="(laSession, numSession) in laFormation.Ppfsessions" >

    <span class="dates" v-if="laSession.frontDates">
        <i class="fa fa-calendar-o" aria-hidden="true"></i>
        <span>{{laSession.frontDates}}</span>
        <button  @blur="infosSessionEnCours = null" v-if="laSession.frontDatesInfos" @click="infosSessionEnCours ? infosSessionEnCours = null : infosSessionEnCours = laSession">
            <img  class="iconeInfos" alt="Information" :src="getImgInfos(infosSessionEnCours, laSession)" />
        </button>
<!--
        <img v-if="laSession.frontDatesInfos && infosSessionEnCours != laSession" @click="infosSessionEnCours = laSession" class="iconeInfos" alt="Information" :src="getImgInfos(infosSessionEnCours, laSession)" />
-->
        <div @click="infosSessionEnCours = null" v-if="laSession.frontDatesInfos && infosSessionEnCours ==laSession" v-html="laSession.frontDatesInfos" class="infosDates"></div>
    </span>
    <span class="lieu"><i class="fa fa-map-marker" aria-hidden="true"></i>
<span>{{laSession.frontLieu}}</span></span>
    <span class="etat"  v-bind:class="laSession.frontEtatClasse"><i class="fa fa-circle" aria-hidden="true"></i>
<span class="etat1">{{laSession.frontEtat}}<span v-if="laSession.frontEtatPlus && laSession.frontEtatPlus.length"><br/>{{laSession.frontEtatPlus}}</span><span  class="etat2" v-if="laSession.frontEtatPlusPlus && laSession.frontEtatPlusPlus.length"><br/>{{laSession.frontEtatPlusPlus}}</span></span></span>
    <span class="infoInscription"  v-if="laSession.frontInscription">{{laSession.frontInscription}}</span>
</div>
                        </div>
                        <div  class="containerInscriptionProgramme" >
                            <div  class="programme marquerLiensExternes" v-if="laFormation.programme_url">
                                <span v-if="laFormation.documents.programme.length">
                                    <a :href="laFormation.documents.programme[0].url" target="_blank">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i> {{laFormation.documents.programme[0].titre}}</a>
                                </span>

                                <span v-if="laFormation.documents.autre.length" class="itemAutreDoc" v-for="(leAutreDoc, numAutreDoc) in laFormation.documents.autre"><span class="separation">/</span>
                                    <a :href="leAutreDoc.url" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> {{leAutreDoc.titre}}</a>
                                </span>

                            </div>
                            <div v-if="laFormation.frontFormationInscriptionPossible" class="containerInscription" >
                                <div  class="inscription">
                                    <a v-bind:ref="laFormation.reference" v-if="laFormation.frontFormationInscriptionPossible > 1"
                                        @click="gererInscription(laFormation)"
                                        class="bouton">
                                    Inscription
                                    </a>
                                    <a  v-bind:target="targetSelonFrame" v-if="laFormation.frontFormationInscriptionPossible == 1"
                                        v-bind:href="laFormation.inscription_url"
                                        class="bouton">
                                    Inscription
                                    </a>
                                </div>
                            </div>
                            <div  class="clear" >
                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <div class="clear"></div>

        </div>
    </div>

  </div>
</template>


<script>


import { addeoGet } from "@/js/datas.js";


export default {
    name: 'Professionnalisation',
    data() {
        return {
            tmp:true,
            ready:false,
            tabTypesFormations:[],
            tabBlocsFormations:[],
            inscriptionFormationEnCours:null,
            inscriptionSessionEnCours:null,
            infosSessionEnCours:null,
            i:0,
            myTimeout:null,
            onlyOnce:true,
            positionAncreId:0,
            hauteurFenetreParent:0,
        }
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    watch: {

        ready(newValue) {
            if (newValue) {
                this.myTimeout = setTimeout(this.lancerClick, 1250);
            }
        },
        positionAncreId(newValue) {
            if (newValue > 0) {
                console.log('postMessage positionAncreId : ', newValue);
                window.parent.postMessage({type: 'topAncre', ancre: newValue}, '*');
            }
        },
/*
        popupFormation(newValue) {
            if (newValue) {
                this.positionnerBasPopup();
            }
        },
*/
        inscriptionFormationEnCours(newValue) {
            if (newValue) {
                this.disableScroll();
                this.calculerHauteurSessions();
                //this.gererPopup();
            }
            else {
                this.enableScroll();
            }
        }
    },
    components: {

    },
    created() {
        if (this.sitePro) {
            this.onlyOnce = true;
            this.positionAncreId=0;
            this.hauteurFenetreParent=0;
            this.$parent.sitePRO = true;
            this.ready = false;
            this.inscriptionFormationEnCours = null;
            this.inscriptionSessionEnCours = null;
            this.infosSessionEnCours = null;
            let thisObj = this;


                    return addeoGet.getProfessionnalisation(this.$store)
                      .then(response => {
                        if (response && response.data && response.data.ok) {
                            let datas = response.data.data;
                            thisObj.traiterDataProfessionnalisation(datas);
                            thisObj.ready = true;
                            console.log("created, getProfessionnalisation OK")
                        }
                        else {
                            console.log("created, getProfessionnalisation KO")
                        }
                    })

                }

    },
    mounted() {
            //this.setUpscroll();
    },
    computed: {
        parentFrame() {
            if (this.isDansIframe) {
                return(document.referrer);
            }
            return null;
        },
        targetSelonFrame() {
            if (this.isDansIframe) {
                return("_top");
            }
            return("_self");
        },
        isDansIframe() {
            if (window.location !== window.parent.location) {
                return(1);
            }
            return(0);
        },
        laRoute() {
            return(this.$route.name);
        },
        tabTypesFormationsFiltre() {
            if (!this.ready) {
                return(null);
            }
            var tabOKs = this.tabTypesFormations.filter(function(item) {
                    return item.compteur;
                });
            return(tabOKs);
        },
        paramRoute() {
            /*
            pas ici - c'est le père
            if (this.parentFrame) {
                let leLink = this.parentFrame.split("?link=");
                if (leLink.length > 1) return(leLink[1]);
            }
            */
            return(this.$route.query.link);
        },
        totalFormations() {
            if (!this.ready) {
                return(0);
                }
            var nbFormations = 0;
            for (let i = 0; i < this.tabTypesFormations.length; i++) {
                nbFormations += parseInt(this.tabTypesFormations[i].compteur);
            }
            return nbFormations;
        },
        totalBlocsFormation() {
            if (!this.ready) {
                return(0);
                }
            var nbBlocsFormation = 0;
            for (let i = 0; i < this.tabTypesFormations.length; i++) {
                nbBlocsFormation += (this.tabTypesFormations[i].compteur != 0);
            }
            return nbBlocsFormation;
        },
        totalBlocsFormationClass() {
            return("nbBlocs"+this.totalBlocsFormation);
        },

        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        sitePRO() {
            return(this.$router.options.markOrientest.paramPRO);
        },
        lUnivers() {
            if (this.markOrientest) {
                return("OrientEst");
            }
            else {
                return("Lorfolio");
            }
        },
    },
    methods: {
        getTopRef(laRef) {
            /*
            let top = this.$refs[laRef][0].getBoundingClientRect().top;
            let top0 = this.$refs['container-professionnalisation'].getBoundingClientRect().top;
            console.log('getTopRef top top0 : ', top, top0);
            return(top-top0);
            */

            console.log('getTopRef laRef : ', "ancre_"+laRef);
            let leItem = document.getElementById("ancre_"+laRef);
            const top = leItem.getBoundingClientRect().top;
            console.log('getTopRef top : ', top);
            return(top);
            },
        initI() {
            this.i = 0;
            return(this.i);
        },
        incI() {
            this.i ++;
            return(this.i);
        },
        lancerClick() {
            let paramRoute = this.paramRoute;
            if (paramRoute) { // cf. parentFrameLink pour comportement iFrame
                let ref2Scroll = "ancre_"+paramRoute;
                if (this.$refs[ref2Scroll] && this.$refs[ref2Scroll][0]) {
                    const el = this.$refs.[ref2Scroll][0].scrollIntoView();
                }
                /* plus de clic
                if (this.$refs[paramRoute] && this.$refs[paramRoute][0]) {
                    this.$refs[paramRoute][0].click();
                }
                */
            }
            else {
                if (this.parentFrame) {
                    this.setUpscroll();
                }
            }
        },
        getImgInfos(infosSessionEnCours, laSession) {
            if (infosSessionEnCours == laSession) {
                return("/img/professionnalisation/zInfosFond.svg");
            }
            return("/img/professionnalisation/zInfos.svg");

        },
        toTop() {
            if (this.myTimeout) {
                clearTimeout(this.myTimeout);
                window.scrollTo(0, 0);
            }
        },
        triTypes(x, y) {
            if (x.ordre_affichage < y.ordre_affichage) {
                return(-1)
            }
            if (x.ordre_affichage > y.ordre_affichage) {
                return(1)
            }
            return(0)
        },
        traiterDataProfessionnalisation(lesDatas) {
            let repertoireImageVignette = "/img/professionnalisation/";
            let typesFormations = lesDatas.reIndexedTypesFormations;
            this.tabTypesFormations = typesFormations.sort(this.triTypes);
// this.tabTypesFormations = this.tabTypesFormations.slice(0, 2)            ; // dev2024
            let thisObj = this;
            for (let i = 0; i < thisObj.tabTypesFormations.length; i++) {
                let typesFormation = thisObj.tabTypesFormations[i];
                switch (typesFormation.code_type) {
                    case "PRO" :
                        typesFormation.imageVignette = repertoireImageVignette + "iAccompagnement.svg";
                        break;
                    case "MET" :
                        typesFormation.imageVignette = repertoireImageVignette + "iInformation.svg";
                        break;
                    case "AGE" :
                    case "REG" :
                        typesFormation.imageVignette = repertoireImageVignette + "iSensibilisation.svg";
                        break;
                    case "IAE" :
                        typesFormation.imageVignette = repertoireImageVignette + "iInsertion.svg";
                        break;
                    case "AUT" :
                        typesFormation.imageVignette = repertoireImageVignette + "iAutres.svg";
                        break;
                }

            }


            let blocsFormations = lesDatas.blocsFormations;
            for (let i = 0; i < blocsFormations.length; i++) {
                let blocFormation = blocsFormations[i];
                if (blocFormation.code_type == thisObj.tabTypesFormations[0].code_type) {
                    thisObj.tabBlocsFormations[0] = blocFormation;
                }
                if (blocFormation.code_type == thisObj.tabTypesFormations[1].code_type) {
                    thisObj.tabBlocsFormations[1] = blocFormation;
                }
/*  dev 2024       */
                if (blocFormation.code_type == thisObj.tabTypesFormations[2].code_type) {
                    thisObj.tabBlocsFormations[2] = blocFormation;
                }
                if (blocFormation.code_type == thisObj.tabTypesFormations[3].code_type) {
                    thisObj.tabBlocsFormations[3] = blocFormation;
                }
                if (blocFormation.code_type == thisObj.tabTypesFormations[4].code_type) {
                    thisObj.tabBlocsFormations[4] = blocFormation;
                }
/* */
                // boucles pour traitement des sessions
                for (let iB = 0; iB < blocFormation.formations.length; iB++) {
                    let formation = blocFormation.formations[iB];
                    formation.frontFormationInscriptionPossible = 0;
                    formation.frontDescriptionIsPrerequis = 0;
                    if (formation.description_is_prerequis == 1 || formation.description_is_prerequis == "1") {
                        formation.frontDescriptionIsPrerequis = 1;
                    }
                    var inscription_url = null;
                    for (let iF = 0; iF < formation.Ppfsessions.length; iF++) {
                        let session = formation.Ppfsessions[iF];
                        thisObj.traiterSession(session);
                        if (session.inscription_possible) {
                            // on affichera le bouton "Inscription" si au moins une session possible
                            formation.frontFormationInscriptionPossible += 1;
                            inscription_url = session.inscription_url;
                        }
                    }
                    if (formation.frontFormationInscriptionPossible == 1) {
                        formation.inscription_url = inscription_url;
                    }
                }
            }
        },
        comparerDates(date1, date2) {
            let tab1 = date1.split('/');
            let tab2 = date2.split('/');
            let nb1 = tab1[2]+tab1[1]+tab1[0];
            let nb2 = tab2[2]+tab2[1]+tab2[0];
            let retour = parseInt(nb1)-parseInt(nb2);
            return(retour);
        },
        traiterSession(session) {
            this.traiterDates(session);
            this.traiterLieu(session);
            this.traiterEtat(session);
        },
        traiterEtat(session) {
            session.frontEtat = "toDoEtat";
            session.frontEtatPlus = "";
            session.frontEtatPlusPlus = "";
            session.frontEtatClasse = "OK";
            session.frontSessionClasse = "";

            if (session.etat == "annulee") {
                session.frontEtat = "Session annulée";
                session.frontEtatClasse = "pasOK";
            }
            else {
/*  13/02/2024 COMPLEt n'est plus prioritaire sur "Inscriptions clôturées"
                if (session.complet && !session.inscription_possible) {
                    session.frontEtat = "Complet";
                    session.frontEtatClasse = "pasOK";
                }
                else {
*/
                    let ladate=new Date()
                    let leJour = ladate.getDate();
                    var leJour0 = "";
                    if (leJour < 10) {
                        leJour0 = "0";
                    }
                    let leMois = ladate.getMonth()+1;
                    var leMois0 = "";
                    if (leMois < 10) {
                        leMois0 = "0";
                    }
                    let ladateFormat = leJour0+leJour+"/"+leMois0+leMois+"/"+ladate.getFullYear();
                    if (session.inscription_cloturee) {
                    /*
                    2024/06 - utilisation du booléen
                    if (this.comparerDates(session.date_fin_inscription_format, ladateFormat) < 0) {
                    */
                        session.frontEtat = "Inscriptions clôturées ";
                        session.frontEtatClasse = "pasOK";
                    }
                    else {
                        //  13/02/2024 COMPLEt n'est plus prioritaire sur "Inscriptions clôturées"
                        if (session.complet && !session.inscription_possible) {
                            session.frontEtat = "Complet";
                            session.frontEtatClasse = "pasOK";
                            return;
                        }

                        if (session.liste_attente) {
                            //session.frontEtatPlusPlus = "Inscriptions en liste d'attente";
                            // modif affichage 06/02/2024
                            session.frontEtat = "Inscriptions en liste d'attente";
                            session.frontEtatClasse = "attention";
                            return;
                        }

                        if (this.comparerDates(session.date_debut_inscription_format,ladateFormat) <= 0) {
                            session.frontEtat = "Inscriptions jusqu'au " + session.date_fin_inscription_format;
                            if (session.nb_places_disponibles > 1) {
                                session.frontEtatPlus = "Plus que " + session.nb_places_disponibles + " places disponibles";
                            }
                            if (session.nb_places_disponibles == 1) {
                                session.frontEtatPlus = "Une seule place encore disponible"
                            }
                        }
                        else {
                            if (this.comparerDates(session.date_debut_inscription_format,session.date_fin_inscription_format)) {
                                session.frontEtat = "Inscriptions du " + session.date_debut_inscription_format+" au "+ session.date_fin_inscription_format;
                            }
                            else {
                                session.frontEtat = "Inscriptions uniquement le " + session.date_debut_inscription_format;
                            }
                            session.frontEtatClasse = "plusTard";
                        }
                    }
/*                }*/
            }
            if (!session.inscription_possible) {
                session.frontSessionClasse = "inscriptionImpossible";
            }
        },
        traiterLieu(session) {
            session.frontLieu = "toDoLieu";
            if (session.lieu) {
                session.frontLieu = session.lieu.toLowerCase();
                // Une session hybride se traite avec simultanément affichés, la modalité et le lieu calculé.
                /**/
                if (session.modalite == "Hybride") {
                    session.frontLieu = "Hybride - " + session.frontLieu;
                }
                /**/
            }
            else {
                session.frontLieu = session.modalite;
            }
        },
        traiterDates(session) {

            session.frontDates = "toDoDate";
            if (session.date_debut_format && session.date_fin_format) {
                if (session.date_debut_format == session.date_fin_format) {
                    session.frontDates = "Le "+ session.date_debut_format;
                }
                else {
                    session.frontDates = "Du "+ session.date_debut_format+ " au "+session.date_fin_format;
                }
            }
            if (session.date_affichage && session.date_affichage.length) {
                //session.frontDatesInfos = "<i class='fermetureDatesInfos fa  fa-times' aria-hidden='true'></i><ul><li>";
                if (session.date_affichage.search("<ul>") >= 0) {
                    session.frontDatesInfos = session.date_affichage;
                }
                else {
                    session.frontDatesInfos = "<ul><li>";
                    session.frontDatesInfos += session.date_affichage;
                    session.frontDatesInfos += "</li></ul>";
                    session.frontDatesInfos = session.frontDatesInfos.replaceAll("<br>", '</li><li>');
                    session.frontDatesInfos = session.frontDatesInfos.replaceAll("<br />", '</li><li>');
                    session.frontDatesInfos = session.frontDatesInfos.replaceAll("<br/>", '</li><li>');
                }
            }

/* Les modifications demandées par Pauline sont abandonnées
            let moisTab = ["", "jan", "fév", "mars", "avr", "mai", "juin", "juil", "août", "sep", "oct", "nov", "déc"];
            session.frontDates = "toDoDate";
            // les dates 11-13 juin 2023 à partir de 04/12/2023
            if (session.date_debut_format && session.date_fin_format) {
                let debutTab = session.date_debut_format.split('/');
                let finTab = session.date_fin_format.split('/');
                if (debutTab[2] == finTab[2]) {
                    if (debutTab[1] == finTab[1]) {
                        if (debutTab[0] == finTab[0]) {
                            // même jour
                            session.frontDates = debutTab[0]+" "+moisTab[parseInt(debutTab[1])]+" "+debutTab[2];
                        }
                        else {
                            // même mois
                            session.frontDates = debutTab[0]+" - "+finTab[0]+" "+moisTab[parseInt(debutTab[1])]+" "+debutTab[2];
                        }
                    }
                    else {
                        // même année
                        session.frontDates = debutTab[0]+" "+moisTab[parseInt(debutTab[1])]+" - "+finTab[0]+" "+moisTab[parseInt(finTab[1])]+" "+debutTab[2];
                    }
                }
                else {
                    // année différente
                    session.frontDates = debutTab[0]+" "+moisTab[parseInt(debutTab[1])]+" "+debutTab[2]+" - "+finTab[0]+" "+moisTab[parseInt(finTab[1])]+" "+finTab[2];
                }
            }
*/
        },
        demanderScrollPopupFormation(laFormation) {
            let popupFormation = document.getElementById('popupFormation');
            const top = popupFormation.getBoundingClientRect().top;
            const bottom = popupFormation.getBoundingClientRect().bottom;
            const y = (top+bottom)/2;
            console.log('demanderScrollPopupFormation top bottom milieu : ', top, bottom, y);
            window.parent.postMessage({type: 'popup', position: y}, '*');
        },
        traiterPopupFormation(laFormation) {
            console.log('traiterPopupFormation ');
            // on modifie la hauteur max de la popup
            if (this.hauteurFenetreParent) {
                console.log('traiterPopupFormation hauteurFenetreParent', this.hauteurFenetreParent);
                let hauteurParent = this.hauteurFenetreParent*0.9;
                document.getElementById("popupFormation").style.maxHeight = ""+hauteurParent+"px";
            }
            setTimeout(function () { this.deplacerPopupFormation(laFormation) }.bind(this), 100);
        },
        deplacerPopupFormation(laFormation) {
            let leTop = this.getTopRef(laFormation.reference);
            console.log('deplacerPopupFormation formation : ', laFormation.reference, leTop);
            let popupFormation = document.getElementById('popupFormation');
            //popupFormation.style.position = 'absolute';
            popupFormation.style.top = leTop+'px';
            setTimeout(function () { this.demanderScrollPopupFormation() }.bind(this), 100);
        },

        gererInscription(laFormation) {
            this.inscriptionFormationEnCours = laFormation;

            if (this.isDansIframe) {
                // on centre la popup
                setTimeout(function () { this.traiterPopupFormation(laFormation) }.bind(this), 100);
            }
            /*
            if (this.isDansIframe) { // si on est dans une iFrame, le scroll se fera en prenant le parent en compte
                windowScroll = window.parent;
                let laIframe = windowScroll.document.getElementById("blockrandom");
                if (laIframe) {
                    let offsetPlus = laIframe.getBoundingClientRect();
                    let topPlus = offsetPlus.top;
                    //alert(topPlus);
                }
            }
            */
        },
        calculerHauteurSessionsTimeout() {
            let hauteurPopup = this.$refs.popup_formation[0].offsetHeight;
            let hauteurBas = this.$refs.container_inscription_programme[0].offsetHeight;
            let $containerSessions = this.$refs.container_sessions[0];
            let yContainerSessions = $containerSessions.offsetTop;
            let calcultHauteurMax = hauteurPopup - (hauteurBas + yContainerSessions);
            $containerSessions.style.maxHeight = ''+calcultHauteurMax+'px';
        },
        calculerHauteurSessions() {
            setTimeout(this.calculerHauteurSessionsTimeout, 500);
        },
/*


        positionnerBoutonsPopup() {
            let laPopup = this.$refs.popup_formation[0];
            let leBas = laPopup.offsetTop+laPopup.offsetHeight;
            let leDiv = this.$refs.container_inscription_programme[0];
            leDiv.top = leBas-leDiv.offsetHeight;
        },
        gererPopup() {
            setTimeout(this.positionnerBoutonsPopup, 1000);
        },
*/
        disableScroll() {
            document.body.parentElement.style.overflow = "hidden";
        },
        enableScroll() {
            document.body.parentElement.style.overflow = "";
        },
        scrollToId(id, event) {
            let cible = document.getElementById(id);
            if (cible) {
                let offsetCible = cible.getBoundingClientRect();
                let leTop = offsetCible.top;
                if (this.isDansIframe) { // si on est dans une iFrame, le scroll se fera en prenant le parent en compte
                    window.parent.postMessage({type: 'scroll', position: leTop}, '*');
                    return;
                }
                window.scrollTo({ top: leTop, behavior: 'smooth' });
            }
        },
        setUpscroll() {
            console.log('setUpscroll');
            setTimeout(function () { this.sendPositionAncre() }.bind(this), 500)
        },
        sendPositionAncre() { // Notification du parent par un 'postMessage'
            let newPositionAncre = this.positionAncreId = this.positionAncre();
            var thisRef = this;
            if ((newPositionAncre == 0) && this.onlyOnce) {
                window.addEventListener('message', function(event) {
                    if (event.data.type === 'urlRef') {
                        let urlRef = event.data.URL;
                        console.log('hauteurFenetreParent : ', event.data.hauteurFenetre);
                        thisRef.hauteurFenetreParent = event.data.hauteurFenetre;
                        console.log('sendPositionAncre urlRef : ', urlRef);
                        thisRef.positionAncreId = thisRef.positionAncre(urlRef);
                        console.log('sendPositionAncre positionAncre : ', thisRef.positionAncreId);
                        //thisRef.onlyOnce = false;
                    }
                    if (event.data.type === 'parentResize') {
                        console.log('hauteurFenetreParent : ', event.data.hauteurFenetre);
                        thisRef.hauteurFenetreParent = event.data.hauteurFenetre;
                        let leConteneur = document.getElementById('containerProfessionnalisation2');
                        if (leConteneur) { // il faut le faire au moins une fois en cas de F5 car rien ne change alors
                            let newHeight = leConteneur.scrollHeight;
                            console.log('hauteurIframe : ', newHeight);
                            window.parent.postMessage({type: 'resizeIframe', height: newHeight}, '*');
                        }
                    }
                }, { once: false });
            }
            window.parent.postMessage({type: 'topAncre', ancre: newPositionAncre}, '*');
        },
        positionAncre(url) {
            if (this.parentFrame) {
                let ref2ScrollLink = this.parentFrameLink(url);
                let ref2Scroll = "ancre_"+ref2ScrollLink;
                console.log('positionAncre ref2Scroll : ', ref2Scroll);
                if (this.$refs[ref2Scroll] && this.$refs[ref2Scroll][0]) {
                    const top = this.$refs.[ref2Scroll][0].getBoundingClientRect().top;
                    if (top && this.onlyOnce) {
                        console.log('positionAncre top', top);
                        this.onlyOnce = false;
                        return(top);
                    }
                    console.log('positionAncre : poursuite');
                    return(0);
                }
                else {
                    if (ref2ScrollLink && document.getElementById("containerBlocs")) {
                        console.log('positionAncre : demande abandon');
                        return(-1); // le bloc est présent mais pas l'id - on n'insiste pas
                    }
                    console.log('positionAncre : demande poursuite');
                    return(0);
                }
            }
            else {
                console.log('positionAncre : abandon');
                return(-1);
            }
        },
        parentFrameLink(url) {
            if (url) {
                let leLinkUrl = url.split("?link=");
                if (leLinkUrl.length > 1)
                    return(leLinkUrl[1]);
            }
            if (this.parentFrame) {
                let leLink = this.parentFrame.split("?link=");
                if (leLink.length > 1)
                    return(leLink[1]);
            }
            return null;
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
/*
#app {
    &.addeoMedium, &.addeoSmall {
        @media screen and (max-width: 800px) {
            #professionnalisation {
                .container-professionnalisation {

                }
            }
        }
    }
}
*/
$largeurMaxTuile1: 550px;
$largeurMaxTuile2: 350px;

#app {
    #professionnalisation {
        .container-professionnalisation {
            .containerTypes {
                &.nbBlocs1, &.nbBlocs2 {
                    /*text-align: center;*/
                }
                &.nbBlocs1 {
                    .itemType {
                        max-width:$largeurMaxTuile1;
                    }
                }
                &.nbBlocs2 {
                    .itemType {
                        max-width:$largeurMaxTuile2;
                    }
                }
            }
        }
    }

    &.addeoSmall {
        #professionnalisation {
            .container-professionnalisation {
                .containerTypes {
                    .itemType {
                        width:32%;
                    }
                    @media screen and (min-width: 501px) {
                        .itemType {
                            &.mult3 {
                                margin-right: 0;
                            }
                        }
                    }
                    @media screen and (max-width: 502px) {
                        .itemType {
                            width:49%;
                            &.impair {
                                margin-right: 0;
                            }
                        }
                    }
                    &.nbBlocs2 {
                        .itemType {
                            width:49%;
                        }
                    }
                    &.nbBlocs1 {
                        .itemType {
                            width:100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.addeoMedium {
        #professionnalisation {
            .container-professionnalisation {
                .containerTypes {
                    .itemType {
                        width:32%;
                    }
                    @media screen and (max-width: 679px) {
                        .itemType {
                            &.mult3 {
                                margin-right: 0;
                            }
                        }
                    }
                    @media screen and (min-width: 680px) {
                        .itemType {
                            width:23.5%;
                        }

                        @media screen and (max-width: 857px) {
                            .itemType {
                                &.mult4 {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    @media screen and (min-width: 858px) {
                        .itemType {
                            width:18.4%;
                        }
                    }
                    &.nbBlocs4 {
                        @media screen and (max-width: 679px) {
                            .itemType {
                                width:32%;
                            }
                        }
                        @media screen and (min-width: 858px) {
                            .itemType {
                                width:23.5%;
                            }
                        }
                    }
                    &.nbBlocs3 {
                        @media screen and (min-width: 679px) {
                            .itemType {
                                width:32%;
                            }
                        }
                    }
                    &.nbBlocs2 {
                        .itemType {
                            width:49%;
                        }
                    }
                    &.nbBlocs1 {
                        .itemType {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
}

#app {
    &.addeoLarge {
        #professionnalisation {
            .container-professionnalisation {
                .containerTypes {
                    &.nbBlocs4 {
                        .itemType {
                            width:23.5%;
                        }
                    }
/* PAS ENCORE */
                    &.nbBlocs3 {
                        .itemType {
                            width:32%;
                        }
                    }
                    &.nbBlocs1, &.nbBlocs2 {
                        a:first-of-type {
                            .itemType {
                                /*margin-right: calc(100% - #{$largeurMaxTuile2} - #{$largeurMaxTuile2});*/
                            }
                        }
                    }
                    &.nbBlocs1 {
                        .itemType {
                            width:100%;
                        }
                    }
                    &.nbBlocs2 {
                        .itemType {
                            width:49%;
                        }
                    }
/* */
                }
            }
        }
    }
    #professionnalisation {

        &.popupEnCours + a {
                display:none;
        }

        #fondPopup {
            z-index:2;
            background-color: black;
            opacity:0.7;
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
        }



        text-align: center;

        .container-professionnalisation {
            box-sizing: border-box !important;
            * {
                box-sizing: border-box !important;
            }
            max-width: 980px;
            text-align: left;
            width:96%;
            padding : 0 2%;
            margin: 0 auto;
            .containerTypes {
                padding: 1em 0;
                a:hover {
                    .itemType {
                        background-color: #F2F1F8;
                    }
                }
                a:last-of-type {
                    .itemType {
                        margin-right: 0;
                    }
                }

                .itemType {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 2px;
                    width:18.4%;
                    min-width:140px;
                    /*width:140px;
                    margin:0 8px 8px 0;*/
                    height:160px;
                    display:inline-block;
                    vertical-align: top;
                    padding : 0;
                    margin:0 2% 8px 0;
                    text-align: center;
                    box-sizing: border-box;

                    span {
                        display:inline-block;
                        position: relative;
                        &.image {
                            width:100%;
                            height:50px;
                            background-color: #D2CEE3;
                            text-align: center;
                            img {
                                height:42px;
                                bottom: 0;
                                left:50%;
                                position: absolute;
                                transform: translate(-50%,0);
                            }
                        }
                        &.contenu {
                            width:92%;
                            padding:0 4%;
                            text-align: left;
                            font-size: 12px;
                            font-weight: 600;
                            color:#2F2F80;
                            line-height: 1.2em;
                            /*margin-top: -2px;*/
                            vertical-align: top;
                        }
                        &.compteur {
                            width:100%;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            color:#356974;
                            position: absolute;
                            left: 0;
                            /*bottom: -2px;*/
                            bottom: 0;
                            i {
                                display: block;
                                /*margin-top: -4px;*/
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }
            .containerBlocs {
                max-width:100%;
                .containerBloc {
                    max-width:100%;
                    color:#2F2F80;
                    /*margin-top:2.5em;*/
                    margin-top:0;
                    padding-top:2.5em;
                    text-align: center;
                    > div, > h3, > p {
                        text-align: left;
                    }
                    .titreBloc {
                        text-transform: uppercase;
                    }
                    .containerFormation {
                        scroll-margin-top: 10px;
                        padding:1em;
                        margin:10px 0 0;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #D8D8D8;

/**/
                        &#popupFormation {
                            overflow: hidden;
                            position: fixed;
                            margin: 0 auto;
                            max-width: 595px;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            width:90vw;
                            max-height:90vh;
                            height:fit-content;
                            z-index:2;
                            border: 1px solid #E4E4E4;
                            border-radius:5px;
                            box-shadow: 0px 3px 6px #00000029;
                            text-align: center;
                            padding:1em 0 50px;


                            > div {
                                text-align: left;
                            }

                            .fermeture {
                                color:#868686;
                                position: absolute;
                                right:1em;
                                top:1em;
                                cursor:pointer;
                                z-index: 3;
                                &:hover  {
                                    opacity:0.8;
                                }
                            }

                            .consigneBloc {
                                text-align: center;
                                margin-bottom:1em;
                                font-weight: 600;
                                font-size: 16px;
                                color:#0E0D0D;
                            }
                            .titreFormation {
                                background: #EDEBF3;
                                text-align: left;
                                margin:0 auto;
                                width:90%;
                                border-radius: 5px;
                                padding:0.6em 1em;
                                font-size:14px;
                            }
                            .titreSessions {
                                padding:0 1em;
                                text-align: left;
                                margin:2em 1em 0 0;
                                font-size: 14px;
                                color: #0E0D0D;
                            }

                            .containerSessions {
                                overflow-y: auto;
                                padding:0 1em;

                                .itemSession {
                                    cursor:pointer;
                                    padding:4px 0 4px 0;
                                    color: #2f2f80 !important;
                                    font-family: "Montserrat", Roboto, Arial, sans-serif;
                                    font-weight: normal;
                                    font-size: 14px;
                                    border-radius:5px;
                                    border: 1px solid #EDEBF3;
                                    margin-top: 10px;
                                    box-shadow: 0 3px 6px #2F2F801A;
                                    &:hover  {
                                        background-color: #EDEBF3;
                                    }
                                    &.sessionEnCours {
                                        background-color: #EDEBF3;
                                        border: 1px solid #4C4190;
                                        box-shadow: none;
                                    }

                                    > span span {
                                        margin-left:0.2em;
                                    }
                                    > span i {
                                        width:20px;
                                        text-align: center;
                                        &:before {
                                            text-align: center;
                                            margin:0 auto;
                                        }
                                    }

                                    &:first-of-type {
                                        margin-top:0.2em !important;
                                    }
                                    &.inscriptionImpossible {
                                        display:none;
                                    }

                                    .dates {
                                        margin-top: 0.1em;
                                    }
                                    .etat {
                                        margin-left:0.6em;
                                        margin-bottom: 0.5em;
                                    }

                                }
                            }

                            .containerInscriptionProgramme {
                                border-top:1px solid #B4B4B4;
                                padding:8px 0 0;
                                position: absolute;
                                bottom:0;
                                width: 100%;
                                max-width: 593px;
                                background-color: white;
                                height: 50px;
                                .containerInscription {
                                    .inscriptionSession {
                                        a.bouton {
                                            margin-left:1em;
                                            &.impossible {
                                                /*opacity:0.4;*/
                                                background-color: #B4B4B4;
                                                border-color: #B4B4B4;
                                                cursor: default;
                                                &:hover {
                                                    background-color: #B4B4B4;
                                                    border-color: #B4B4B4;
                                                    color: rgba(255,255,255,0.8);
                                                }
                                            }
                                            &.nonPrioritaire {
                                                background-color: white;
                                                color: #356A73;
                                                opacity:0.7;
                                                &:hover {
                                                    opacity:1;
                                                }
                                            }
                                        }
                                    }
                                }
                                .programme {
                                    display:none;
                                }
                            }
                        }
/**/

                        .titreFormation {
                            .refFormation {
                                font-weight: normal;
                            }
                            margin-bottom:1em;
                        }
                        .description, .sessionVide {
                            margin-bottom:1em;
                        }
                        .description {
                            font-size: 13px;
                        }
                        .sessionVide {
                            color:#707070;
                            font-size: 0.9em;
                        }
                        .containerSessions {
                            margin-bottom:2em;
                            .itemSession > span {
                                vertical-align: middle;
                                display:inline-block;
                                min-width:200px;
                                margin-left: 1em;
                                span {
                                    margin-left: 0.6em;
                                }
                            }
                            @media screen and (max-width: 840px) {
                                .itemSession > span.etat {
                                    display:block;
                                    margin:0.6em 1em 1.6em;
                                    width: fit-content;
                                }
                            }
                            @media screen and (max-width: 700px) {
                                .itemSession > span {
                                    display:block;
                                    margin:0.6em 1em;
                                }
                            }
                            .itemSession {
                                line-height: 1.4em;
                                margin: 1.4em 0 1em;
                                font-size:14px;
                                .dates {
                                    position: relative;
                                    font-weight: 500;
                                    font-family: "Montserrat Medium", Roboto, Arial, sans-serif;
                                    min-width:265px;
                                    .iconeInfos {
                                        vertical-align: middle;
                                        margin:0 1em 0 0.3em;
                                        cursor:pointer;
                                    }
                                    .infosDates {
                                        cursor:pointer;
                                        font-size:12px;
                                        font-family: "Montserrat", Roboto, Arial, sans-serif;
                                        font-weight: normal;
                                        color:#131213;
                                        position:absolute;
                                        right:2em;
                                        background-color: #EBF8FB;
                                        box-shadow: 0px 3px 6px #00000045;
                                        z-index: 1;
                                        padding:1.2em 1em 0.8em 0.5em ;
                                        width: max-content;
                                        .fermetureDatesInfos {
                                            color:#356974;
                                            position:absolute;
                                            top:5px;
                                            right:5px;
                                        }
                                        ::marker {
                                            content:"";
                                        }
                                        li {
                                            &:before {
				                                font-family: Dosis-Bold, sans-serif;
                                                font-weight:bold;
                                                font-size:16px;
                                                content:".";
                                                vertical-align: super;
                                                padding: 0 0.2em;
                                                line-height: 0.6em;
                                            }
                                        }
                                    }
                                }
                                .lieu {
                                    font-weight: 500;
                                    font-family: "Montserrat Medium", Roboto, Arial, sans-serif;
                                    min-width:230px;
                                    span {
                                        text-transform: capitalize;
                                    }
                                }
                                .etat {
                                    font-size:12px;
                                    min-width: 0;
                                    border-radius:8px;
                                    padding:0.2em 0.6em;
                                    line-height: 1.2em;
                                    i {
                                        font-size: 0.5em;
                                        padding-bottom: 0;
                                    }
                                    &.OK {
                                        background-color:#ECFAF1;
                                        i {
                                            color: #30C865;
                                        }
                                    }
                                    &.pasOK {
                                        background-color:#FFEEEE;
                                        i {
                                            color: #FF4040;
                                        }
                                    }
                                    &.plusTard {
                                        background-color:#F7F7F7;
                                        i {
                                            color: #868686;
                                        }
                                    }
                                    &.attention {
                                        background-color:#F4F4F4;
                                    }
                                    &.inscriptionImpossible {
                                    }
                                }
                                i {
                                    font-size:0.9em;
                                    padding-bottom:0.2em;
                                }
                                span > span, span > i {
                                    vertical-align: middle;
                                }
                            }
                        }
                        .containerInscriptionProgramme {
                            .programme, .containerInscription {
                                display:inline-block;
                            }
                            .programme {
                                font-size:0.9em;
                                margin:0 0 1em auto;
                                a {
                                    line-height: 2em;
                                    border-bottom: 1px solid #356A73;
                                    i {
                                        font-size:0.9em;
                                        vertical-align: middle;
                                        padding-bottom:0.4em;
                                    }
                                    &:hover {
                                        border-bottom: none;
                                    }
                                }
                                a[target=_blank] {
                                    &:after {
                                        content: url("~@/assets/img/oe/outv.svg");
                                        height: auto;
                                        display: inline-block;
                                        width: 8px;
                                        margin-left: 4px;
                                        vertical-align: super;
                                        vertical-align: top;
                                        margin-top: -2px;
                                    }
                                }
                                .itemAutreDoc {
                                    .separation {
                                        margin:0 1.2em;
                                    }
                                }
                            }
                            .containerInscription {
                                float:right;
                                margin: 0 1em;
                                a.bouton {
                                    text-transform: uppercase;
                                    display:inline-block;
                                    border: 1px solid #356A73;
                                    background-color: #356974;
                                    color: rgba(255,255,255,0.8);
                                    padding: 0.4em 0.9em;
                                    font-size: 0.9em;
                                    font-weight: 600;

                                    &:hover {
                                        /*opacity: 0.5;*/
                                        background-color: white;
                                        color: #356A73;
                                        cursor:pointer;
                                    }
                                    &:focus {
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #footer {
        margin-top: 0;
    }
}
</style>
